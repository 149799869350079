const TOLERANCE = 1e-9;

/**
 * Retorna se dois valores reais (float) são iguais
 * @param {number} a first value
 * @param {number} b second value
 */
export default function compareFloatNum(a, b) {
  return Math.abs(a - b) < TOLERANCE;
}
