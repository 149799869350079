var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TableV',{attrs:{"actionBarButtons":_vm.actionBarButtons,"cols":_vm.cols,"disableTableSaveButton":_vm.disableTableSaveButton,"hasExportCSV":false,"lastRowData":_vm.lastRowData,"opts":_vm.opts,"rows":_vm.rows},on:{"contextmenu":_vm.contextHandler,"save":_vm.doUpdate}},[_c('div',{staticClass:"d-flex"},[_c('v-menu',{attrs:{"close-on-click":false,"close-on-content-click":false,"transition":"scroll-y-transition","offset-y":"","right":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-n1 mt-0",staticStyle:{"width":"370px"},attrs:{"prefix":"Período:","value":_vm._f("toPeriod")(_vm.period),"dense":"","prepend-icon":"mdi-calendar","readonly":"","hide-details":""},on:{"click:prepend":function($event){$event.stopPropagation();return on.click($event)}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.periodMenu),callback:function ($$v) {_vm.periodMenu=$$v},expression:"periodMenu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","events":_vm.highlightWeekOver,"event-color":_vm.tableColor},on:{"mouseenter:date":_vm.highlightWeek,"mouseleave:date":function($event){_vm.highlightWeekOver = []},"click:date":_vm.dateClick},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1),(_vm.isUserColaborador)?_c('v-autocomplete',{staticClass:"mb-n1 mt-0 ml-3",staticStyle:{"width":"370px"},attrs:{"items":_vm.opts.colaboradores,"item-value":"id","item-text":"nome","prefix":"Colaborador","prepend-icon":"mdi-account","placeholder":"Todos","dense":"","clearable":"","hide-details":""},on:{"change":_vm.debouncedLoad},model:{value:(_vm.colaboradorId),callback:function ($$v) {_vm.colaboradorId=$$v},expression:"colaboradorId"}}):_vm._e()],1),_c('FormModal',{attrs:{"cols":_vm.cols,"errorMessage":_vm.createModalMessage,"opened":_vm.openedCreateModal,"opts":_vm.opts,"title":"Alocar semana de trabalho","value":_vm.selectedItem},on:{"update:opened":function($event){_vm.openedCreateModal=$event},"update:value":function($event){_vm.selectedItem=$event},"save":_vm.createModalSave}}),_c('GModal',{ref:"confirmDeleteDay",attrs:{"title":"Confirmar exclusão do dia"},scopedSlots:_vm._u([{key:"buttons",fn:function(ref){
var close = ref.close;
return [_c('v-spacer'),_c('v-btn',{staticClass:"px-3",attrs:{"color":"secondary","dark":"","depressed":""},on:{"click":function($event){return close(false)}}},[_vm._v(" Não ")]),_c('v-btn',{staticClass:"px-3",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return close(true)}}},[_vm._v(" Sim ")]),_c('v-spacer')]}}])},[_c('p',{staticStyle:{"max-width":"500px"}},[_vm._v(" Deseja excluir o registro do dia "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.dia))]),_vm._v(" para o projeto "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.projeto || "Título não encontrado"))]),(_vm.selectedItem.atividade)?[_c('span',[_vm._v(", atividade "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.atividade))])])]:_vm._e(),(_vm.selectedItem.funcionarioNome)?[_c('span',[_vm._v(", colaborador "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.funcionarioNome))])])]:_vm._e(),_vm._v(" ? ")],2),_c('p',[_vm._v("Essa ação não pode ser desfeita.")])]),_c('GModal',{ref:"confirmDeleteWeek",attrs:{"title":"Confirmar exclusão"},scopedSlots:_vm._u([{key:"buttons",fn:function(ref){
var close = ref.close;
return [_c('v-spacer'),_c('v-btn',{staticClass:"px-3",attrs:{"color":"secondary","dark":"","depressed":""},on:{"click":function($event){return close(false)}}},[_vm._v(" Não ")]),_c('v-btn',{staticClass:"px-3",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return close(true)}}},[_vm._v(" Sim ")]),_c('v-spacer')]}}])},[_c('p',{staticStyle:{"max-width":"500px"}},[_vm._v(" Deseja excluir todos os registros da semana para o projeto "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.projeto || "Título não encontrado"))]),(_vm.selectedItem.atividade)?[_c('span',[_vm._v(", atividade "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.atividade))])])]:_vm._e(),(_vm.selectedItem.funcionarioNome)?[_c('span',[_vm._v(", colaborador "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.funcionarioNome))])])]:_vm._e(),_vm._v(" ? ")],2),_c('p',[_vm._v("Essa ação não pode ser desfeita.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }